import React, { useState } from "react"
import styled from "@emotion/styled"

import Modal from "./Modal"

const Container = styled.section`
  padding: 15rem 0;
  position: relative;
  background: #0f2027; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #2c5364,
    #203a43,
    #0f2027
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #2c5364,
    #203a43,
    #0f2027
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  overflow: hidden;
  svg {
    width: 101%;
    position: absolute;
    left: 0;
    fill: #fff;
    z-index: 01;
    :nth-of-type(1) {
      top: 0;
    }
    :nth-of-type(2) {
      bottom: 0;
    }
  }
`

const Content = styled.div`
  max-width: 130rem;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  @media only screen and (max-width: 56.25em) {
    grid-template-columns: 1fr;
  }
`

const Box = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 5rem;
  cursor: pointer;
`

const Image = styled.img`
  height: 200px;
  padding: 3rem;
  filter: grayscale(100%);
  transition: all 0.3s;
  ${Box}:hover & {
    filter: grayscale(0%);
  }
  @media only screen and (max-width: 56.25em) {
    max-width: 80vw;
    min-height: 100px;
  }
`

const Primary = styled.span`
  font-weight: 400;
  color: #fff;
  transition: all 0.3s;
  cursor: pointer;
  ${Box}:hover & {
    color: #2196f3;
  }
`

const OnTv = () => {
  const CTV = (
    <iframe
      src="https://webapps.9c9media.com/vidi-player/1.7.10/share/iframe.html?currentId=1516859&config=ctvnews/share.json&kruxId=ImoeZsch&rsid=&siteName=CTVNews&cid=%5B%7B%22contentId%22%3A1516859%2C%22ad%22%3A%7B%22adsite%22%3A%22ctv.ctvnews%22%2C%22adzone%22%3A%22embed%22%7D%7D%5D"
      width="640"
      height="360"
      frameborder="0"
      title="ctv"
      onLoad={() => setLoaded(true)}
    ></iframe>
  )
  const GLOBAL_NEWS = (
    <iframe
      src="https://globalnews.ca/video/embed/4482793/"
      width="640"
      height="360"
      frameborder="0"
      title="global"
      onLoad={() => setLoaded(true)}
    ></iframe>
  )

  const [loaded, setLoaded] = useState(false)
  return (
    <Container>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 35">
        <path d="M638.32 10.15c-36.07 29.398-176.926 23.403-268.34 4.762C227.45-22.232 95.918 85.944-29.442-.114c-34.574-24.06-6.842-70.252 2.577-95.885 9.25-25.626 28.103-40.729 6.248-87.866-22.024-47.129-84.756-126.292-61.35-131.924 23.237-5.625 132.897 62.396 212.502 96.974 79.722 34.7 129.727 35.94 177.387 52.404 47.608 16.595 79.974 85.855 154.562 78.792 159.286-15.086 202.955 51.34 175.835 97.768z" />
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 47">
        <path d="M207.579 209.5C132.451 229.322 20.34 278.283.093 269.89c-20.247-8.393 51.332-74.367 51.317-118.823-.304-44.51-72.024-67.542-96.774-93.61C-70.006 31.485-47.5 2.436 4.628 2.503c52.019-.026 133.947 29.12 191.082 36.429 57.026 7.216 89.08-7.365 152.096-17.285 63.017-9.92 156.817-15.142 244.559 2.536 88.03 17.733 169.75 58.536 147.644 88.32-21.998 29.876-147.606 48.92-228.304 63.147-80.878 14.266-116.846 23.713-153.813 23.757-37.075-.049-75.077-9.633-150.313 10.094z" />
      </svg>
      <Content>
        <Modal data={CTV} loaded={loaded} setLoaded={setLoaded}>
          <Box>
            <Image src="/img/ctv.svg" alt="CTV logo" />
            <Primary>Check us out on CTV News</Primary>
          </Box>
        </Modal>
        <Modal data={GLOBAL_NEWS} loaded={loaded} setLoaded={setLoaded}>
          <Box>
            <Image src="/img/global.png" alt="Global logo" />
            <Primary>Check us out on Global News</Primary>
          </Box>
        </Modal>
      </Content>
    </Container>
  )
}
export default OnTv
