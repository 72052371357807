import React from "react"
import styled from "@emotion/styled"

import Card from "./Card"

const Container = styled.section`
  max-width: 130rem;
  margin: 3rem auto;
  padding: 0 3rem;
`

const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 3rem;
  @media only screen and (max-width: 37.5em) {
    grid-template-columns: 1fr;
  }
`

const Primary = styled.h2`
  text-align: center;
  font-size: 3.5rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.3;
  color: #2196f3;
  background: linear-gradient(to right, #2196f3, #2dc7ff);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
`

const Subtitle = styled.h3`
  font-weight: 400;
  font-size: 1.9rem;
  text-align: center;
  padding: 1.5rem 10rem;
  @media only screen and (max-width: 52em) {
    padding: 1.5rem 0;
  }
`

const Secondary = styled.h4`
  font-size: 2.25rem;
  font-weight: 400;
  text-align: center;
  color: #505050;
  text-decoration: none;
`

const Body = styled.span`
  display: block;
  font-size: 2rem;
  padding: 1.5rem 0;
  font-weight: 400;
  color: #909090;
  transition: all 0.3s;
`

const Articles = ({ data: { title, subtitle, data } }) => {
  return (
    <Container>
      <Primary>{title}</Primary>
      <Subtitle>{subtitle}</Subtitle>
      <Content>
        {data &&
          data.map((item, count) => {
            if (item) {
              const { title, author, url } = item
              return (
                <a
                  href={url}
                  key={count}
                  rel="nofollow"
                  style={{ textDecoration: "none" }}
                >
                  <Card>
                    <Secondary>{title}</Secondary>
                    <Body>By {author}</Body>
                  </Card>
                </a>
              )
            }
            return null
          })}
      </Content>
    </Container>
  )
}

export default Articles
