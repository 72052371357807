import React from "react"
import styled from "@emotion/styled"

import { createHTML } from "../utils"

const Body = styled.section`
  padding: 3rem;
  max-width: 130rem;
  margin: 0 auto;
  h1 {
    text-align: center;
    font-size: 3.5rem;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1.3;
    color: #2196f3;
    background: linear-gradient(to right, #2196f3, #2dc7ff);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
  }
  h2 {
    font-size: 2rem;
    color: #505050;
    padding-top: 1.5rem;
  }
  p {
    padding: 1rem 0;
  }
  a {
    color: #2196f3;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  ol {
    padding-top: 2rem;
    padding-left: 1.75rem;
  }
  ul {
    padding-top: 2rem;
    padding-left: 1.75rem;
    text-align: left;
  }
  img {
    object-fit: contain;
    max-width: 100%;
  }
  table {
    border-collapse: collapse;
  }
  th {
    padding: 0.5rem 1.5rem;
    border: 1px solid #dfe2e5;
  }
  td {
    padding: 0.5rem 1.5rem;
    border: 1px solid #dfe2e5;
  }
  blockquote {
    margin-top: 1rem;
    padding-left: 3rem;
    border-left: 2px solid #dfe2e5;
  }
  code {
    font-size: 1.7rem;
    font-style: oblique;
  }
`

const MarkdownBody = ({ data }) => {
  return <Body dangerouslySetInnerHTML={createHTML(data)} />
}

export default MarkdownBody
